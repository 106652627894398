import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import flexible from 'rem-flexible'
// 设计稿宽度为1920px，每1rem等于10px
// flexible(1920, 10)
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-flash'
import 'videojs-contrib-hls'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

import "./assets/css/public.css"
import "./assets/css/style.css"

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$video = Video

// 全局id
Vue.prototype.webId = '132ECD49-5C96-4336-8E6D-51208FBB041E'
Vue.prototype.webIdGroup = 'BEE8AB69-65E3-4161-ACF7-0E09E4028BB8'

// 日期dayjs
import dayjs from 'dayjs'
Vue.prototype.dayjs = dayjs

// 引入高德地图
import VueAMap from 'vue-amap'
Vue.use(VueAMap)

//引入无缝轮播
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

// 初始化vue-amap
VueAMap.initAMapApiLoader({
    // 高德的key
    key: 'b4ec94ce069dbb1948540c8e25e0cccf',
    // 插件集合
    plugin: [
        'AMap.MouseTool',
        'AMap.PolyEditor',
        'AMap.LabelMarker',
        'AMap.Autocomplete',
        'AMap.ToolBar',
        'AMap.PlaceSearch',
        'AMap.Heatmap',
        'AMap.Geocoder',
        'AMap.DistrictSearch'
    ],
    uiVersion: '1.0',
    // 高德 sdk 版本
    v: '1.4.15'
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')